import { useEffect } from 'react';

const GoogleTagManager = () => {
    useEffect(() => {
        // Create the script element for Google Tag Manager
        const script = document.createElement('script');
        script.src = "https://www.googletagmanager.com/gtag/js?id=G-1MLJGLLSTX";
        script.async = true;
        document.head.appendChild(script);

        // Create the inline script for Google Tag Manager configuration
        const inlineScript = document.createElement('script');
        inlineScript.innerHTML = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-1MLJGLLSTX');
        `;
        document.head.appendChild(inlineScript);
    }, []);

    return null;
};

export default GoogleTagManager;
