import React from 'react';

function termfoter() {

    return (
        <>
        
        <div className="row3">
        <p style={{ color: 'white' }}>
          © Copyright <b>Valora Infotech</b>. All Rights Reserved
        </p>
      </div>
        </>
      );

}

export default termfoter;